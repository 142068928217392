
   
import React from 'react';
import './Player.css';
import OvenPlayer from 'ovenplayer';

class Player extends React.Component {
    props : {app?: string, id?: string} = { id : ''};
    

    constructor(props: { id? : string }) {
        super(props);
    }

    componentDidMount():void {
        this.setState({
            isShow:true
        });
        const player = OvenPlayer.create('remoteVideo',  {
            sources: [
                {
                    label: 'low latency live',
                    type: 'webrtc',
                    file: 'wss://streaming.stream.studio/'+this.props.app+'/'+this.props.id
                }
            ]
        });
    }

    streamChangeHandler = (target:HTMLInputElement):void => {
        this.setState({streamName: target.value});
    }
    
    render() {
        return <div className="studioPlayerContainer">
                <video width="100%" height="100%" className="studioPlayer" id="remoteVideo" autoPlay controls playsInline></video>
            </div>
        
    }
    
}

export default Player;