import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import Embed from "./pages/Embed";

function App() {
  return (
    <Routes>
      <Route path="/:app/:id" element={<Embed />} />
    </Routes>
  );
}

export default App;
